import React, { useState, useEffect } from "react";
import {
  TextField,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  Box,
  Autocomplete,
  Typography,
  ListSubheader,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import "moment/locale/en-gb";
import { api, apiOLR } from "../api/index";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

const departments = ["แผนก A", "แผนก B", "แผนก C"];
const contactPersons = ["Mr. A", "Ms. B", "Dr. C"];
const contactList = [
  "ผู้รับเหมา(ไม่เข้าอาคาร)",
  "ส่งเอกสาร",
  "ผู้รับเหมา (เข้าอาคาร)",
  "ส่งสินค้า (เข้าอาคาร)",
  "VIP",
  "Guest",
  "ผู้มาติดต่อ",
  "สถานศึกษา",
  "Co-working space",
];

const Step3 = ({ }) => {
  const methods = useFormContext();
  const { control, formState, watch, setValue, handleSubmit, reset } = methods;
  const { errors } = formState;
  const [personType, setPersonType] = useState([]);
  const [areas, setAreas] = useState([]);
  const [department, setDepartment] = useState([]);
  const [persons, setPersons] = useState([]);
  const [dummyPersons, setDummyPersons] = useState([]);
  const [cilkup, setCilkup] = useState(0);


  const colorGroups = [
    "ผู้รับเหมา (ไม่เข้าอาคาร)",
    "ส่งเอกสาร",
    "ผู้รับเหมา (เข้าอาคาร)",
    "ส่งสินค้า (เข้าอาคาร)",
    "ผู้มาติดต่อ",
    "สถานศึกษา",
    "Co-working space",
  ];

  useEffect(() => {
    handleRefresh();
  }, []);
  async function handleRefresh(data) {
    const responsepersontype = await api
      .get(
        `/online_register/online_register_objective`, { params: {} },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    setPersonType(responsepersontype?.data?.data || [])
    const responseareas = await api
      .get(
        `/online_register/areas`, { params: {} },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    setAreas(responseareas?.data?.data || [])
    const responsedepartment = await api
      .get(
        `/online_register/department`, { params: {} },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    setDepartment(responsedepartment?.data?.data || [])

  }
  const handleSearchPersons = async (data) => {
    if (data?.length) {
      api
        .get(
          `/online_register/person`,
          {
            params: {
              "search_cols[first_name|last_name|personal_id]":
                data || undefined,
              department_id: watch("department")?.id || undefined,
              person_type: 1,
              blacklist: 0,
              isDeleted: false,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((e) => {
          if (e?.data?.count > 0 && e?.data?.ret == 0) {
            //   // setPartner(e)
            setPersons(e?.data?.data);
          } else {
            setPersons([]);
          }
        });
    } else {
      setPersons(dummyPersons);
    }
  };
  const handleGetPersons = async (data) => {
    const responseperson = await api.get(
      `/online_register/person`,
      {
        params: {
          department_id: data?.id || undefined,
          person_type: 1,
          blacklist: 0,
          isDeleted: false,
          limit: 50,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    setDummyPersons(responseperson?.data?.data || []);
    setPersons(responseperson?.data?.data || []);
  };

  useEffect(() => {
    handleGetPersons(watch("department"));
  }, [watch("department")]);

  return (
    <div className="p-32">
      <Grid container spacing={2}>
        <Grid item xs={12} className="mb-20 display-center">
          <Grid item xs={12} md={6} className="mb-20">
            <Controller
              name="object_id"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  noOptionsText={"กรุณากรอกข้อมูลเพื่อค้นหา"}
                  {...field}
                  options={personType}
                  getOptionLabel={(option) => {
                    return typeof option === 'string' ? option : option?.reg_obj_name;
                  }}
                  value={field.value ? watch("object") : null}
                  onChange={(_, data) => {
                    field.onChange(data?.id || "");
                    setValue("object", data || null);
                    setCilkup(pre => pre + 1)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="ประเภทบุคคล"
                      variant="outlined"
                      InputLabelProps={{
                        style: {
                          fontSize: "16px",
                          fontFamily: "Kanit, sans-serif",
                        },
                      }}
                      required
                      sx={{
                        ".MuiInputLabel-asterisk.MuiInputLabel-asterisk": {
                          color: "red",
                        },
                        "& .MuiInputBase-root": {
                          fontSize: "16px",
                          fontFamily: "Kanit, sans-serif",
                        },
                        ".MuiSvgIcon-root": {
                          fontSize: "24px",
                        },
                      }}
                      fullWidth
                    />
                  )}
                  renderOption={(props, option) => {
                    // let color = "black";
                    // switch (option) {
                    //   case "ผู้รับเหมา (ไม่เข้าอาคาร)":
                    //     color = "green";
                    //     break;
                    //   case "ส่งเอกสาร":
                    //     color = "blue";
                    //     break;
                    //   case "ผู้รับเหมา (เข้าอาคาร)":
                    //     color = "red";
                    //     break;
                    //   case "ส่งสินค้า (เข้าอาคาร)":
                    //     color = "yellow";
                    //     break;
                    //   case "ผู้มาติดต่อ":
                    //   case "สถานศึกษา":
                    //   case "Co-working space":
                    //     color = "blue";
                    //     break;
                    //   default:
                    //     color = "black";
                    //     break;
                    // }
                    return (
                      <li {...props} key={option}>
                        <Grid container alignItems="center">
                          <Grid item xs>
                            <Typography
                              variant="body1"
                              color="initial"
                              sx={{
                                fontSize: "16px !important",
                                fontFamily: "Kanit, sans-serif !important",
                                // color: color,
                              }}
                            >
                              {option?.reg_obj_name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </li>
                    );
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} className="mb-20">
          <Controller
            name="area_id"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                noOptionsText={"กรุณากรอกข้อมูลเพื่อค้นหา"}
                multiple
                options={areas}

                onChange={(_, data) => {
                  field.onChange(data)
                  setCilkup(pre => pre + 1)
                }}
                getOptionLabel={(option) => option?.area_name}
                filterSelectedOptions
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                sx={{
                  "& .MuiAutocomplete-noOptions": {
                    fontSize: "50px",
                    fontFamily: "Kanit, sans-serif",
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // required={contactListValue === "ผู้รับเหมา (เข้าอาคาร)"}
                    required
                    inputProps={{
                      ...params.inputProps,
                      required: field?.value?.length === 0
                    }}
                    label="ขออนุญาตเข้าพื้นที่"
                    variant="outlined"
                    InputLabelProps={{
                      style: {
                        fontSize: "16px",
                        fontFamily: "Kanit, sans-serif",
                      },
                    }}
                    sx={{
                      ".MuiInputLabel-asterisk.MuiInputLabel-asterisk": {
                        color: "red",
                      },
                      "& .MuiInputBase-root": {
                        fontSize: "16px",
                        fontFamily: "Kanit, sans-serif",
                      },

                      ".MuiSvgIcon-root": {
                        fontSize: "24px",
                      },
                    }}
                    fullWidth
                  />
                )}
                renderTags={(selected, getTagProps) =>
                  selected.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      label={option?.area_name}
                      sx={{
                        background: "#05A2F3",
                        ".MuiChip-label": {
                          padding: "12px",
                          fontSize: "16px",
                          fontFamily: "Kanit, sans-serif !important",
                          fontWeight: 400,
                          color: "#ffffff",
                        },
                      }}
                    />
                  ))
                }
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option}>
                      <Grid container alignItems="center">
                        <Grid item xs>
                          <Typography
                            variant="body1"
                            color="initial"
                            sx={{
                              fontSize: "16px !important",
                              fontFamily: "Kanit, sans-serif !important",
                            }}
                          >
                            {option?.area_name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </li>
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} className="mb-20">
          <Controller
            name="detail"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                InputLabelProps={{
                  // shrink: true,
                  style: {
                    fontSize: "16px",
                    fontFamily: "Kanit, sans-serif",
                  },
                }}
                sx={{
                  ".MuiInputLabel-asterisk.MuiInputLabel-asterisk": {
                    color: "red",
                  },
                  "& .MuiInputBase-root": {
                    fontSize: "16px",
                    fontFamily: "Kanit, sans-serif",
                  },
                }}
                label="วัตถุประสงค์"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid item xs={6} className="mb-20">
          {/* {console.log(watch("department"))} */}
          <Controller
            name="department"
            control={control}
            render={({ field }) => (
              <Autocomplete
                noOptionsText={"กรุณากรอกข้อมูลเพื่อค้นหา"}
                // {...field}
                options={department}
                getOptionLabel={(option) => option?.department_name}
                onChange={(_, data) => {
                  field.onChange(data);
                  setValue(`contactPerson`, null);
                  setCilkup(pre => pre + 1)
                }}
                value={watch("department") || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="แผนก"
                    variant="outlined"
                    InputLabelProps={{
                      style: {
                        fontSize: "16px",
                        fontFamily: "Kanit, sans-serif",
                      },
                    }}
                    sx={{
                      ".MuiInputLabel-asterisk.MuiInputLabel-asterisk": {
                        color: "red",
                      },
                      "& .MuiInputBase-root": {
                        fontSize: "16px",
                        fontFamily: "Kanit, sans-serif",
                      },
                      ".MuiSvgIcon-root": {
                        fontSize: "24px",
                      },
                    }}
                    fullWidth
                  />
                )}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option}>
                      <Grid container alignItems="center">
                        <Grid item xs>
                          <Typography
                            variant="body1"
                            color="initial"
                            sx={{
                              fontSize: "16px !important",
                              fontFamily: "Kanit, sans-serif !important",
                            }}
                          >
                            {option?.department_name}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                              fontSize: "16px !important",
                              fontFamily: "Kanit, sans-serif !important",
                            }}
                          >
                            {option?.company?.company_name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </li>
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} className="mb-20">
          <Controller
            name="contactPerson"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Autocomplete
                // className="mt-8 mb-16 mx-4"
                noOptionsText="ไม่มีรายการ"
                getOptionLabel={(option) => {
                  return typeof option === "string"
                    ? option
                    : `${option?.first_name} ${option?.last_name}`;
                }}
                // filterOptions={createFilterOptions({
                //   // join with some arbitrary separator to prevent matches across adjacent terms
                //   stringify: (option) => option?.company_code + "" + option?.company_name
                // })}
                // filterOptions={(x) => x}
                options={persons}
                // freeSolo
                filterSelectedOptions
                value={value ? watch("contactPerson") : null}
                name="search"
                fullWidth
                required
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!errors?.contactPerson}
                helperText={errors?.contactPerson?.message}
                onChange={async (event, newValue) => {
                  onChange(newValue || null);


                  if (newValue?.department) {
                    setValue(`department`, newValue?.department || null, {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }
                  setCilkup(pre => pre + 1)
                  // setValue(`personal_id`, newValue?.personal_id || null);
                  // setValue(`person`, newValue || null, { shouldDirty: true, shouldValidate: true });
                  // if (newValue?.id) {
                  //   const res = await handlegetPersons(newValue);
                  //   onChange(res?.payload?.personal_id || '');
                  //   setValue(`person`, res?.payload || null, { shouldDirty: true, shouldValidate: true });
                  // }
                  // setValue(`company_code`, newValue?.company_code || "")
                }}
                onInputChange={(event, newInputValue) => {
                  handleSearchPersons(event?.target?.value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{
                      style: {
                        fontSize: "16px",
                        fontFamily: "Kanit, sans-serif",
                      },
                    }}
                    sx={{
                      ".MuiInputLabel-asterisk.MuiInputLabel-asterisk": {
                        color: "red",
                      },
                      "& .MuiInputBase-root": {
                        fontSize: "16px",
                        fontFamily: "Kanit, sans-serif",
                      },
                      ".MuiSvgIcon-root": {
                        fontSize: "24px",
                      },
                    }}
                    label="ผู้ติดต่อ"
                    fullWidth
                    variant="outlined"
                    required
                    error={!!errors?.contactPerson}
                    helperText={errors?.contactPerson?.message}
                  // size="small"
                  />
                )}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      <Grid container alignItems="center">
                        <Grid item xs>
                          <Typography
                            variant="body1"
                            color="initial"
                            sx={{
                              fontSize: "16px !important",
                              fontFamily: "Kanit, sans-serif !important",
                            }}
                          >
                            {option?.personal_id}
                          </Typography>

                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                              fontSize: "16px !important",
                              fontFamily: "Kanit, sans-serif !important",
                            }}
                          >
                            {option?.first_name} {option?.last_name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </li>
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} className="mb-20">
          <Controller
            name="startdate"
            control={control}
            render={({ field }) => (
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale="en-gb"
              >
                <FormControl fullWidth error={!!errors.startdate}>
                  <DatePicker
                    {...field}
                    label="วันที่เริ่มต้น"
                    clearable
                    sx={{
                      ".MuiInputBase-root": {
                        fontSize: "16px",
                        fontFamily: "Kanit, sans-serif",
                      },
                      ".MuiFormLabel-root": {
                        fontSize: "16px !important",
                        fontFamily: "Kanit, sans-serif !important",
                      },
                      ".MuiSvgIcon-root": {
                        fontSize: "24px",
                      },
                      ".MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
                        color: "red",
                      },
                    }}
                    slotProps={{
                      textField: {
                        required: true,
                        error: !!errors.startdate,
                        helperText: errors?.startdate?.message,
                      },
                      layout: {
                        sx: {
                          borderRadius: "20px",
                          "& .MuiPickersDay-root": {
                            fontSize: "12px ",
                            fontWeight: "500",
                            fontFamily: "Kanit, sans-serif !important",
                            "&.Mui-selected": {
                              backgroundColor: "#05A2F3",
                            },
                          },
                          "& .MuiPickersYear-yearButton": {
                            fontSize: "12px ",
                            fontWeight: "500",
                            fontFamily: "Kanit, sans-serif !important",
                            "&.Mui-selected": {
                              backgroundColor: "#05A2F3",
                            },
                          },
                          ".MuiPickersCalendarHeader-label": {
                            fontSize: "18px !important",
                            fontWeight: "500",
                            fontFamily: "Kanit, sans-serif !important",
                          },
                          ".MuiDayCalendar-weekDayLabel": {
                            fontSize: "12px !important",
                            fontWeight: "500",
                            fontFamily: "Kanit, sans-serif !important",
                          },
                        },
                      },
                      leftArrowIcon: { fontSize: "large" },
                      rightArrowIcon: { fontSize: "large" },
                      switchViewIcon: { fontSize: "large" },
                      field: {
                        clearable: true,
                      },
                    }}
                    inputFormat="DD/MM/YYYY"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        error={!!errors.startdate}
                        helperText={errors?.startdate?.message}
                        onKeyDown={(e) => null}
                      />
                    )}
                  />
                </FormControl>
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid item xs={6} className="mb-20">
          <Controller
            name="enddate"
            control={control}
            render={({ field }) => (
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale="en-gb"
              >
                <FormControl fullWidth error={!!errors.enddate}>
                  <DatePicker
                    {...field}
                    label="วันที่สิ้นสุด"
                    clearable
                    sx={{
                      ".MuiInputBase-root": {
                        fontSize: "16px",
                        fontFamily: "Kanit, sans-serif",
                      },
                      ".MuiFormLabel-root": {
                        fontSize: "16px !important",
                        fontFamily: "Kanit, sans-serif !important",
                      },
                      ".MuiSvgIcon-root": {
                        fontSize: "24px",
                      },
                      ".MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
                        color: "red",
                      },
                    }}
                    slotProps={{
                      textField: {
                        required: true,
                        error: !!errors.enddate,
                        helperText: errors?.enddate?.message,
                      },
                      layout: {
                        sx: {
                          borderRadius: "20px",
                          "& .MuiPickersDay-root": {
                            fontSize: "12px ",
                            fontWeight: "500",
                            fontFamily: "Kanit, sans-serif !important",
                            "&.Mui-selected": {
                              backgroundColor: "#05A2F3",
                            },
                          },
                          "& .MuiPickersYear-yearButton": {
                            fontSize: "12px ",
                            fontWeight: "500",
                            fontFamily: "Kanit, sans-serif !important",
                            "&.Mui-selected": {
                              backgroundColor: "#05A2F3",
                            },
                          },
                          ".MuiPickersCalendarHeader-label": {
                            fontSize: "18px !important",
                            fontWeight: "500",
                            fontFamily: "Kanit, sans-serif !important",
                          },
                          ".MuiDayCalendar-weekDayLabel": {
                            fontSize: "12px !important",
                            fontWeight: "500",
                            fontFamily: "Kanit, sans-serif !important",
                          },
                        },
                      },
                      leftArrowIcon: { fontSize: "large" },
                      rightArrowIcon: { fontSize: "large" },
                      switchViewIcon: { fontSize: "large" },
                      field: {
                        clearable: true,
                      },
                    }}
                    inputFormat="DD/MM/YYYY"
                    //   error={!!errors.valid_from}
                    //   helperText={errors?.valid_from?.message}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        error={!!errors.enddate}
                        helperText={errors?.enddate?.message}
                        onKeyDown={(e) => null}
                      />
                    )}
                  />
                </FormControl>
              </LocalizationProvider>
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Step3;
