import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import {
  Button,
  Card,
  Container,
  CardContent,
  CardActions,
  Box,
  useMediaQuery,
  Typography,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { api } from "../../api/index";
import Swal from "sweetalert2";

const Approve = () => {
  const navigate = useNavigate();
  const [action, setAction] = useState(null);
  const [token, setToken] = useState(null);
  const [code, setCode] = useState(null);
  const [remark, setRemark] = useState("");
  const [status, setStatus] = useState("pending");

  const fetchData = async (dtoken, dcode, daction) => {
    const response = await api
      .post(
        `/online_register/check_code`,
        {
          token: dtoken,
          code: dcode,
          action: parseInt(daction),
          rejected_remark: daction == -1 ? remark : undefined,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(async (ret) => {
        // "rejected" ปฏิเสธการอนุมัติไปล้ว
        // "approved" อนุมัติไปแล้ว
        // "success" ตอนกดบันทึก
        // console.log(ret.data.data);
        setStatus(ret.data.data);
      })
      .catch(function (error) {
        // 1ไม่พบข้อมูล
        // console.log(error);
        // setStatus("pending");
        setAction(null);
        setToken(null);
        setCode(null);
      });
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const actionParam = params.get("action");
    const tokenParam = params.get("token");
    const codeParam = params.get("code");
    // const closeWindow = () => {
    //   setTimeout(() => {
    //     alert("กรุณาปิดหน้าเว็บนี้ด้วยตนเองหากยังเปิดอยู่");
    //   }, 1000);
    // };

    // เรียกใช้ closeWindow
    // closeWindow();

    if (actionParam) setAction(actionParam);
    if (tokenParam) setToken(tokenParam);
    if (codeParam) setCode(codeParam);

    if (actionParam && tokenParam && codeParam) {
      fetchData(tokenParam, codeParam, actionParam);
    } else {
      // กลับไปหน้าแรกถ้าขาด parameter ใดๆ
      // closeWindow();
      return;
    }
  }, []);

  return (
    <div className="bg">
      <Container className="content-center pt-32">
        <Card
          className="shadow rounded-2xl"
          sx={{
            position: "relative",
            marginTop: "79px",
            padding: "64px",
            height:
              !action || !token || !code
                ? "20vh"
                : status === "pending"
                ? action == 0
                  ? "35vh"
                  : "20vh"
                : status !== "pending"
                ? "15vh"
                : "20vh",
          }}
        >
          {!action || !token || !code ? (
            <>
              <div style={{ marginBottom: 32 }}>
                <Typography
                  sx={{
                    color: "#D32F2F",
                    fontSize: "40px",
                    fontFamily: "Kanit, sans-serif",
                    fontWeight: 600,
                  }}
                >
                  ข้อมูลไม่ถูกต้อง
                </Typography>
              </div>
              <div>
                <Typography
                  sx={{
                    color: "#D32F2F",
                    fontSize: "35px",
                    fontFamily: "Kanit, sans-serif",
                    fontWeight: 400,
                  }}
                >
                  กรุณาติดต่อเจ้าหน้าที่
                </Typography>
              </div>
            </>
          ) : status === "pending" ? (
            action == 0 ? (
              <>
                <div style={{ marginBottom: 32 }}>
                  <Typography
                    sx={{
                      color: "#D32F2F",
                      fontSize: "40px",
                      fontFamily: "Kanit, sans-serif",
                      fontWeight: 600,
                    }}
                  >
                    ปฏิเสธการลงทะเบียน
                  </Typography>
                </div>
                <div>
                  <TextField
                    label="หมายเหตุในการปฏิเสธการลงทะเบียน"
                    variant="outlined"
                    multiline
                    rows={3}
                    onChange={(e) => setRemark(e.target.value)}
                    sx={{
                      width: "578px",
                      borderRadius: "10px",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                        "& fieldset": {
                          borderColor: "#0072C1",
                        },
                        "& textarea": {
                          height: "136px",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        fontFamily: "Kanit, sans-serif",
                        fontWeight: 400,
                        fontSize: "16px",
                        color: "#00000099",
                      },
                      "& .MuiInputBase-root": {
                        fontFamily: "Kanit, sans-serif",
                        fontWeight: 400,
                        fontSize: "16px",
                        color: "#000000",
                      },
                    }}
                  />
                </div>
                <div style={{ justifyItems: "center", paddingTop: 32 }}>
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: "24px",
                      fontFamily: "Kanit, sans-serif",
                      fontWeight: 400,
                      width: "745px",
                    }}
                  >
                    ระบบจะนำส่งข้อมูลการปฏิเสธการลงทะเบียน ใบงานเลขที่ {code}{" "}
                    ไปยังบุคคลในรายชื่อผ่านทาง Email
                  </Typography>
                </div>
                <div style={{ paddingTop: 32 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    className="bt-danger"
                    onClick={(e) => {
                      fetchData(token, code, -1);
                    }}
                  >
                    บันทึก
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div style={{ marginBottom: 32 }}>
                  <Typography
                    sx={{
                      color: "#2E7D32",
                      fontSize: "40px",
                      fontFamily: "Kanit, sans-serif",
                      fontWeight: 600,
                    }}
                  >
                    การอนุมัติสำเร็จ
                  </Typography>
                </div>
                <div>
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: "24px",
                      fontFamily: "Kanit, sans-serif",
                      fontWeight: 400,
                    }}
                  >
                    ใบงานเลขที่ {code} ได้รับการอนุมัติเข้าพื้นที่แล้ว
                    ระบบจะนำส่ง QRcode สำหรับยื่นให้กับเจ้าหน้าที่ รปภ.
                    เพื่อแลกบัตรก่อนเข้าพื้นที่ให้กับบุคคลในรายชื่อผ่านทาง Email
                  </Typography>
                </div>
              </>
            )
          ) : status === "approved" ? (
            <>
              <div style={{ marginBottom: 32 }}>
                <Typography
                  sx={{
                    color: "#2E7D32",
                    fontSize: "40px",
                    fontFamily: "Kanit, sans-serif",
                    fontWeight: 600,
                  }}
                >
                  ใบงานเลขที่ {code}
                </Typography>
              </div>
              <div>
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: "24px",
                    fontFamily: "Kanit, sans-serif",
                    fontWeight: 400,
                  }}
                >
                  ได้รับการอนุมัติเรียบร้อยแล้ว
                </Typography>
              </div>
            </>
          ) : status === "rejected" ? (
            <>
              <div style={{ marginBottom: 32 }}>
                <Typography
                  sx={{
                    color: "#D32F2F",
                    fontSize: "40px",
                    fontFamily: "Kanit, sans-serif",
                    fontWeight: 600,
                  }}
                >
                  ใบงานเลขที่ {code}
                </Typography>
              </div>
              <div>
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: "24px",
                    fontFamily: "Kanit, sans-serif",
                    fontWeight: 400,
                  }}
                >
                  ปฏิเสธการขออนุมัติ
                </Typography>
              </div>
            </>
          ) : status === "success" ? (
            <>
              <div style={{ marginBottom: 32 }}>
                <Typography
                  sx={{
                    color: "#05A2F3",
                    fontSize: "40px",
                    fontFamily: "Kanit, sans-serif",
                    fontWeight: 600,
                  }}
                >
                  ใบงานเลขที่ {code}
                </Typography>
              </div>
              <div>
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: "24px",
                    fontFamily: "Kanit, sans-serif",
                    fontWeight: 400,
                  }}
                >
                  ได้รับการบันทึกสำเร็จแล้ว
                </Typography>
              </div>
            </>
          ) : null}
        </Card>
      </Container>
    </div>
  );
};

export default Approve;
