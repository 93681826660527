import React from "react";
import { AppBar, Toolbar, Typography, Box } from "@mui/material";
import { styled } from "@mui/system";

const ResponsiveImg = styled("img")(({ theme }) => ({
  height: "32px",
  width: "192px",
  maxWidth: "100%",
  maxHeight: "100%",
  //   [theme.breakpoints.down("sm")]: {
  //     height: "150px",
  //     width: "150px",
  //     maxWidth: "100%",
  //     maxHeight: "100%",
  //   },
  //   [theme.breakpoints.down("xs")]: {
  //     height: "100px",
  //     width: "100px",
  //     maxWidth: "100%",
  //     maxHeight: "100%",
  //   },
}));

export default function Header() {
  return (
    <AppBar position="static" sx={{ bgcolor: "#111827" }}>
      {/* เปลี่ยนสีที่นี่ */}
      <Toolbar>
        <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
          <ResponsiveImg src="/assents/logos/AccessLink_long2.png" alt="Logo" />
          {/* เปลี่ยน path ของรูปที่นี่ */}
        </Box>
      </Toolbar>
    </AppBar>
  );
}
