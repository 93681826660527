import axios from "axios";
// const base_url = "https://smc-olr.taisolution.tech/backend/api/v0";
// const api = axios.create({
//   baseURL: "https://smc-olr.taisolution.tech/backend/api/v0",
// });
const base_url = `${process.env.REACT_APP_API_URL}/backend/api/v0`;
const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/backend/api/v0`,
});
const apiOLR = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL_S}/backend/api/v0`,
  headers: {
    "accept": "application/json",
    "X-OLR-API-Key": "access_people"
  }
});

export { api, base_url, apiOLR };
